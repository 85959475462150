<template>
  <b-overlay :show="loading" class="col-12 col-lg-12">
    <div class="card card-body" style="height: 100%; margin: 0px !important">
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="member.avatar"
              size="104px"
              style="background: #999"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h2 class="mb-0">
                  {{ member.name }}
                  <br />
                  <span class="text-muted">{{ member.number }}</span>
                </h2>
              </div>
              <div class="d-flex flex-wrap">
                <b-button
                  :to="'/users/edit/' + member._id"
                  variant="success"
                  class="btn-relief-success"
                >
                  <i class="fa fa-edit text-dark"></i>
                  {{ e("edit") }}
                </b-button>
                &nbsp;
                <b-button
                  variant="primary"
                  @click="freedays()"
                  class="btn-relief-primary"
                >
                  <i class="fa fa-calendar"></i>
                  {{ e("freedays") }}
                </b-button>
                &nbsp;
                <b-button @click="deleteUser(member._id)">
                  <i class="fa fa-trash text-danger"></i>
                  {{ e("delete") }}
                </b-button>
              </div>
            </div>
          </div>

          <!-- User Stats -->
          <div
            class="d-flex align-items-center mt-2 table-responsive"
            style="overflow-y: hidden"
          >
            <div class="d-flex align-items-center mr-2">
              <b-avatar variant="light-success" rounded>
                <feather-icon icon="CheckIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">{{ e("total-attend-days") }}</h5>
                <small>{{ member.attends }}</small>
              </div>
            </div>
            <div class="d-flex align-items-center mr-2">
              <b-avatar variant="light-warning" rounded>
                <feather-icon icon="ClockIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">{{ e("total-lates-days") }}</h5>
                <small>{{ member.lates }}</small>
              </div>
            </div>
            <div class="d-flex align-items-center mr-2">
              <b-avatar variant="light-danger" rounded>
                <feather-icon icon="ClockIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">{{ e("total-lates-attends") }}</h5>
                <small>{{ member.late_attends }} {{ e("minute") }}</small>
              </div>
            </div>
            <div class="d-flex align-items-center mr-2">
              <b-avatar variant="light-danger" rounded>
                <feather-icon icon="ClockIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">{{ e("total-lates-breaks") }}</h5>
                <small>{{ member.late_breaks }} {{ e("minute") }}</small>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col cols="12" xl="6">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">{{ e("name") }}</span>
              </th>
              <td class="pb-50">
                {{ member.name }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">{{ e("number") }}</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ member.number }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">{{ e("phone") }}</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ member.phone }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">{{ e("add-date") }} </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ member.date }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">{{ e("username") }} </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ member.username }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="KeyIcon" class="mr-75" />
                <span class="font-weight-bold">{{ e("password") }} </span>
              </th>
              <td class="pb-50 text-capitalize">
                <span class="pass_fd">
                  <span v-if="!viewPassword">*****</span>
                  <span v-if="viewPassword">{{ member.password }}</span></span
                >&nbsp;
                <a @click="viewPasswordFun()" :title="e('view')"
                  ><feather-icon icon="EyeIcon" class="mr-75"
                /></a>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="SettingsIcon" class="mr-75" />
                <span class="font-weight-bold">{{ e("settings-group") }} </span>
              </th>
              <td class="pb-50">
                {{ groupName(member.settings_group_id) }}
              </td>
            </tr>
            <tr v-if="user.branches">
              <th class="pb-50">
                <feather-icon icon="HomeIcon" class="mr-75" />
                <span class="font-weight-bold">{{ e("branches") }}</span>
              </th>
              <td class="pb-50">
                <span v-if="member.all_branches">
                  <strong>* مسموح بكل الفروع *</strong>
                </span>
                <span v-if="!member.all_branches">
                  <ul>
                    <li v-for="b in member.branches" :key="b">
                      {{ brancheName(b) }}
                    </li>
                  </ul>
                </span>
              </td>
            </tr>
          </table>
        </b-col>
        <div class="col-12 g text-center">
          <b-link
            :to="'/reports/day?number=' + member.number"
            class="btn btn-relief-success btn-lg"
          >
            <i class="fa-regular fa-calendar"></i>
            {{ e("day-attend-report") }}
          </b-link>
          &nbsp;
          <b-link
            :to="'/reports/between?number=' + member.number"
            class="btn btn-relief-primary btn-lg"
          >
            <i class="fa fa-calendar"></i>
            {{ e("between-attend-report") }}
          </b-link>
          &nbsp;
          <b-button @click="$router.push('/users/app-devices#' + member._id)">
            <i class="fa fa-mobile text-danger"></i>
            {{ e("app_devices") }}
          </b-button>
        </div>
      </b-row>
    </div>
    <div class="row g">
      <div class="col-12 col-lg-6 g">
        <div class="card">
          <div class="card-header">
            <h4>
              <i class="fa fa-list"></i>
              {{ e("device-log") }}
            </h4>
          </div>
          <div class="card-body">
            <div
              class="col-12 table-responsive"
              style="overflow-y: scroll; height: 600px"
            >
              <table class="table table-hover custom-table">
                <thead>
                  <th>
                    {{ e("date") }}
                  </th>
                  <th>
                    {{ e("serial-number") }}
                  </th>
                  <th>
                    {{ e("content") }}
                  </th>
                  <th>
                    {{ e("action") }}
                  </th>
                </thead>
                <tbody>
                  <tr v-for="log in member.logs" :key="log._id">
                    <td>
                      {{ log.date }}
                    </td>
                    <td>
                      {{ log.serial_number }}
                    </td>
                    <td>
                      <span v-if="log.records.length > 1">
                        <textarea
                          name=""
                          class="form-control"
                          v-html="recordsValidate(log.records)"
                        ></textarea>
                      </span>
                      <span v-if="log.records.length == 1">
                        {{ log.records[0].id + " | " + log.records[0].time }}
                      </span>
                    </td>
                    <td>
                      {{ e(log.type) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 g">
        <div class="card">
          <div class="card-header">
            <h4>
              <i class="fa fa-times"></i>
              {{ e("rejected-reports") }}
            </h4>
          </div>
          <div class="card-body">
            <div
              class="col-12 table-responsive"
              style="overflow-y: scroll; height: 600px"
            >
              <table class="table table-hover custom-table">
                <thead>
                  <th>
                    {{ e("serial-number") }}
                  </th>
                  <th>
                    {{ e("date") }}
                  </th>
                  <th>
                    {{ e("time") }}
                  </th>
                  <th>
                    {{ e("reason") }}
                  </th>
                </thead>
                <tbody>
                  <tr v-for="report in member.rejected" :key="report._id">
                    <td>
                      {{ report.serial_number }}
                    </td>
                    <td>
                      {{ report.date }}
                    </td>
                    <td>
                      {{ report.time }}
                    </td>
                    <td>
                      {{ e("reason-" + report.reason) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-1" hide-footer>
      <div class="col-12 text-center">
        <b-link
          :to="'/freedays?number=' + member.number"
          class="btn btn-relief-success"
        >
          <i class="fa fa-plus"></i>
          {{ e("add") }} </b-link
        ><br /><br />
      </div>
      <div class="col-12 table-responsive">
        <table class="table table-hover">
          <tbody>
            <tr v-for="day in member.freedays" :key="day._id">
              <td>
                {{ cdate(day.date) }}
              </td>
              <td>
                {{ day.to == "all" ? e("all") : e("for-him") ? day.to : "--" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </b-overlay>
</template>
<script>
import {
  BCard,
  BButton,
  BLink,
  BAvatar,
  BRow,
  BCol,
  BOverlay,
  VBModal,
  BCardText,
} from "bootstrap-vue";
var moment = require("moment-timezone");
var moment2 = require("moment-hijri");
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import VueDatetimeJs from "vue-datetime-js";

const a2e = (s) => s.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d));
export default {
  components: {
    BCard,
    BButton,
    BAvatar,
    BLink,
    datePicker: VueDatetimeJs,
    BRow,
    BCol,
    BAvatar,
    BOverlay,
    VBModal,
    BCardText,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      viewPassword: false,
      user: JSON.parse(localStorage.getItem("user")),
      loading: true,
      member: {
        rejected: [],
        logs: [],
      },
      groups: [],
    };
  },
  created() {
    var g = this;
    $.post(api + "/general/settings-groups/index", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.groups = JSON.parse(r);
        $.post(api + "/users/overview", {
          jwt: g.user.jwt,
          id: window.location.pathname.split("/overview/")[1],
        })
          .then(function (e) {
            e = JSON.parse(e);
            g.member = e;
            g.loading = false;
          })
          .catch(function (e) {
            alert(e);
          });
      })
      .catch(function (e) {
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "Error: " + e,
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    viewPasswordFun() {
      if (this.viewPassword) {
        this.viewPassword = false;
      } else {
        this.viewPassword = true;
      }
    },
    e(d) {
      return e(d);
    },
    cdate(d) {
      return cdate(d);
    },
    groupName(id) {
      var title = null;
      this.groups.forEach((element) => {
        if (element._id == id) {
          title = element.title;
        }
      });
      return title;
    },
    freedays() {
      this.$bvModal.show("modal-1");
    },
    deleteUser(id) {
      var g = this;
      if (confirm(e("confirm"))) {
        $.post(api + "/users/delete", {
          jwt: this.user.jwt,
          id: id,
        })
          .then(function (r) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e("done"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
            g.$router.push("/users");
          })
          .catch(function (e) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "Error: " + e,
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    brancheName(id) {
      var title = e("notfound");
      this.user.branches_list.forEach((b) => {
        if (b.id == id) {
          title = b.title;
        }
      });
      return title;
    },
    recordsValidate(arr) {
      var n = "";
      arr.forEach(function (a) {
        n = n + `${a.id} | ${a.time}\n`;
      });
      return n;
    },
  },
};
</script>
